import React from 'react';
import SEO from "../../components/seo/Seo";
import Header from "../../components/headers/Header";
import Footer from "../../components/layouts/Footer";
import MineCulinaryHeader from "../../components/mine-culinary/MineCulinaryHeader";
import MineCulinaryPartOne from "../../components/mine-culinary/MineCulinaryPartOne";
import MineCulinaryPartTwo from "../../components/mine-culinary/MineCulinaryPartTwo";
import MineCulinaryInfoBoxes from "../../components/mine-culinary/MineCulinaryInfoBoxes";
import MineCulinaryForm from "../../components/mine-culinary/MineCulinaryForm";

function SitarjevskiHodi(props) {
    return (
        <div>
            <SEO title="Sitarjevški hodi" description="Obiskovalci Rudnika Sitarjevec Litija lahko ob obisku Izvoznega rova poskusijo tradicionalno »sitarjevško malco« ali pa »škratov šmorn«"/>

            <Header/>
            <MineCulinaryHeader/>
            <MineCulinaryPartOne/>
            <MineCulinaryInfoBoxes/>
            <MineCulinaryPartTwo/>
            <MineCulinaryForm />

            <Footer/>
        </div>
    );
}

export default SitarjevskiHodi;