import React, {Component, Fragment} from "react";
import {FiClock, FiCompass, FiPlayCircle, FiTag} from "react-icons/fi";


class MineCulinaryInfoBoxes extends Component {
    render() {
        let Data = [
            {
                icon: <FiClock/>,
                text: "4. april 2025 ob 18. uri",
                countTitle: 'Kdaj',
            },

            {
                icon: <FiCompass/>,
                text: "Rudnik Sitarjevec Litija",
                countTitle: 'Izhodišče',
            },

            {
                icon: <FiPlayCircle/>,
                text: "2 uri",
                countTitle: 'Trajanje',
            },

            {
                icon: <FiTag/>,
                text: "59€",
                countTitle: 'Cena',
            }
        ];

        return (
            <Fragment>
                <div className>
                    <div className="info-boxes">
                        <div className="container">
                            <div className="row">
                                {Data.map((value, index) => (
                                    <div className="im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                                        <div className="im_counterup single-counterup-box">
                                            <div className="inner">
                                                <div className="mine-counter-icon">
                                                    {value.icon}
                                                </div>
                                                <div className="counter-value">
                                                    {value.text}
                                                </div>
                                                <p className="counter-description">{value.countTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MineCulinaryInfoBoxes;