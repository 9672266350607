import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {Link} from "gatsby";

const scrollToPrijava = () => {
    const prijava = document.getElementById('prijava');

    // Scroll to the start of the element
    prijava.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function CuisineDescription(props) {
    return (
        <>
            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2 className="title"></h2>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 pr-lg-4">
                            <div className="">
                                <div className="section-title">
                                    <h3 className="title"><span className="text-primary">Vrhunski kuharski mojster.</span><br/>Najboljše lokalne sestavine. Izjemne (sitarjevške) zgodbe. </h3>
                                    <h3 className="title"><span className="text-primary">Fuzija rudarske zgodovine in sodobnega</span>. Zlitje prostora in kulinarike. </h3>
                                    <p className="description mt-4"><strong>Meni s 4 hodi</strong> bo pripravila izkušena ekipa kuharskega <strong>šefa Marka Železnika</strong>, ki ustvarja v Krčmi na gradu Bogenšperk. Tokratno kulinarično doživetje bo v prav posebnem podzemnem ambientu - v Rudniku Sitarjevec Litija.</p>

                                    <p className="description">
                                        <strong>Edinstvena zgodovina rudnika </strong> bo navdih za pripravo posebnih jedi, narejenih iz izbranih lokalnih sestavin, tudi takih, ki nosijo certifikat najvišje kakovosti Srca Slovenije.
                                    </p>

                                    <p className="description">
                                        <strong>Kulinarične mojstrovine,</strong> zaokrožene z izbranimi slovenskimi vini in žganjem, bodo s svojo barvno paleto stilsko umeščene v naravno barvitost rudnika, ki ponuja izjemne mineralne podobe.
                                    </p>

                                    <p className="description"> <strong>Surovine;</strong>  lokalne, sezonske, avtentične, so temelj zdrave prehrane. Dovolite, da vas očarajo tudi <strong>pod zemljo</strong>, kjer je svet drugačen. Hrana ima boljši okus. </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/rudarski-hod-3.jpg"
                                    alt="Sitarjevška malca in Škratov šmorn"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper ptb--80 bg-primary text-white">
                <div className="container">
                    <div className="row justify-content-center mx-auto">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="text-white mx-auto">
                                <h3 className="title text-center lead-2 text-white">Dogodek je med hodi začinjen z zgodbami rudnika. Celostno doživetje prizemlji človeka in izostri čute.</h3>
                                <h4 className="title text-center">Vabljeni na gastronomsko popotovanje, sprehod po rudniku in pripovedovanje!</h4>

                                <div className="mx-auto text-center">
                                    <button onClick={scrollToPrijava} className="btn-default btn-border btn-large btn-white mt--20">Prijavite se</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CuisineDescription;